import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { API_URL } from 'src/config/url';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

import { BehaviorSubject, Observable } from 'rxjs';
import { RuntimeEnvironment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userInfo = new BehaviorSubject<any>(null);
  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private cookie: CookieService,
  ) {}
  login(credentials: any) {
    return this.http.post<any>(`${API_URL}/auth/authenticate`, credentials);
  }

  storeToken(res: any) {
    const { token, refreshToken, role } = res;
    this.cookie.set('presence', token, 1, '/', RuntimeEnvironment.DOMAIN_HOST, false, 'None');
    this.cookie.set('_ref', refreshToken, 1, '/', RuntimeEnvironment.DOMAIN_HOST, false, 'None');
    this.cookie.set('_sid', role, 1, '/', RuntimeEnvironment.DOMAIN_HOST, false, 'None');
  }
  getSession() {
    return {
      token: this.cookie.get('presence'),
      refreshToken: this.cookie.get('_ref'),
      role: this.cookie.get('_sid'),
    };
  }
  getUserRoles(): any {
    const token = this.getSession()?.token;
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);

      let roles = [];

      roles = decodedToken.roles.map((role: any) => role?.authority);
      if (!decodedToken.roles || decodedToken.roles.length === 0) {
        roles = ['SUPER'];
      }

      return roles;
    }
  }

  isAuthorized(requiredRoles: string[]) {
    if (requiredRoles && requiredRoles.length > 0) {
      const userRoles = this.getUserRoles();
      if (userRoles) {
        const hasRequiredRole = requiredRoles.some((role) => userRoles.includes(role));
        if (hasRequiredRole) {
          return true;
        }
      }
    }

    return false;
  }

  isTokenExpired() {
    const { token } = this.getSession();
    if (token) {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      return expiry * 1000 <= Date.now();
    }
    return false;
  }

  getTenantId(): any {
    const token = this.getSession()?.token;
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken?.tenantId;
    }
  }

  change_tenant(toPartner: boolean) {
    const { refreshToken } = this.getSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${refreshToken}`,
    });

    const requestOptions = { headers: headers };
    return this.http.post(`${API_URL}/auth/tenant`, { toPartner }, requestOptions);
  }

  isAuthenticate() {
    if (this.cookie.get('presence') && this.cookie.get('_sid')) {
      return true;
    } else {
      return false;
    }
  }

  me() {
    return this.http.get<any>(`${API_URL}/auth/me`);
  }

  getUserInfo(): Observable<any> {
    return this.userInfo.asObservable();
  }

  setUserInfo(user: any) {
    this.userInfo.next(user);
  }

  logout(lastPath: string) {
    // window.location.href = 'https://www.maisontpe.fr/auth/logout';
    window.location.href = 'localhost:4200';
  }
}
