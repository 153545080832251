export const DEFAULT_LINKS = [
  {
    title: 'Tableau de bord',
    path: '/',
    icon: 'dashboard',
  },
  {
    title: 'Liste de sociétés',
    path: '/societes',
    icon: 'bank',
  },
  {
    title: 'Mon Cabinet',
    path: '/mon-cabinet',
    icon: 'user',
  },

  {
    title: 'Paramètre',
    path: '/settings',
    icon: 'setting',
  },
];

export const LINKS_COMPANY = [
  {
    title: 'Retour aux listes',
    path: '/societes',
    icon: 'arrow-left',
    authorizedRoles: ['ADMINCABINET'],
  },
  {
    title: 'Info société',
    path: '/societes/info',
    icon: 'bank',
  },
  {
    title: 'Etablissement',
    path: '/societes/info/etablissements',
    icon: 'home',
  },
  {
    title: 'Collaborateurs',
    path: '/societes/info/collaborateurs',
    icon: 'idcard',
  },
  {
    title: 'Candidats',
    path: '/societes/info/candidats',
    icon: 'idcard',
  },
  {
    title: 'Gestion des absences',
    path: '/agencies',
    icon: 'file-excel',
  },
  {
    title: 'Gestion des fichies',
    path: '/users',
    icon: 'user',
  },

  {
    title: 'historique',
    path: '/travel-history',
    icon: 'history',
  },
  {
    title: 'Statistiques',
    path: '/stats',
    icon: 'pie-chart',
  },

  {
    title: 'Paramètrage',
    path: '/settings',
    icon: 'setting',
  },
];

export const LINKS_WORKER = (fullName: string) => [
  {
    title: 'Retour aux listes',
    path: '/societes/info/collaborateurs',
    icon: 'arrow-left',
    authorizedRoles: ['ADMINSOCIETE', 'ADMINCABINET'],
  },
  {
    title: 'Identité ' + fullName || 'Nom et prénom',
    path: '/societes/info/collaborateurs/profile',
    icon: 'user',
  },
  {
    title: 'Administratif',
    path: '/societes/info/collaborateurs/profileAdministratif',
    icon: 'user',
  },
  {
    title: 'Planning',
    icon: 'clock-circle',
    children: [
      {
        title: 'Semaine de référance',
        path: '/societes/info/collaborateurs/profile/planning',
        icon: 'clock-circle',
      },
      {
        title: 'Planning',
        path: '/societes/info/collaborateurs/profile/planning/planningWorker',
        icon: 'clock-circle',
      },
    ],
  },
  {
    title: 'Contrats',
    path: '/societes/info/collaborateurs/profile/contrats',
    icon: 'file-text',
  },

  {
    title: 'Contrats Extra',
    path: '/societes/info/collaborateurs/profile/contrat-extra',
    icon: 'file',
  },
  {
    title: "Feuille d'absence",
    path: '/societes/info/collaborateurs/profile/absences',
    icon: 'file-excel',
  },
  {
    title: 'Fiche de paie',
    path: '/societes/info/collaborateurs/profile/fiche-paie',
    icon: 'file-search',
  },
  {
    title: 'Historique Congés',
    path: '/societes/info/collaborateurs/profile/historique-conges',
    icon: 'history',
  },
  {
    title: 'Paramètre',
    path: '/societes/info/collaborateurs/profile/parametre',
    icon: 'setting',
  },
];
