import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  public isCompanyView: boolean = false;
  public isWorkerView: boolean = false;
  public currentRoute?: string;

  constructor(
    public location: Location,
    private sharedService: SharedService,
    public router: Router
  ) {
    this.currentRoute = location.path();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
        this.handleNullValuesAndUrls();
        this.handleMenu();
        this.blockUnAuthorizedUrls();
      }
    });
  }

  handleMenu() {
    this.isCompanyView =
      this.currentRoute?.includes('societes/info') &&
      this.sharedService.getIDInfo()?.companyId;

    this.isWorkerView =
      this.currentRoute?.includes('collaborateurs') &&
      this.sharedService.getIDInfo()?.workerId;
  }

  handleNullValuesAndUrls() {
    this.router.events.subscribe((event: any) => {
      if (event) {
        let url = event?.url || event?.routerEvent.url;
        if (!url?.includes('societes/info')) {
          this.sharedService.setCompanyId(null);
          this.isCompanyView = false;
        }
        if (!url?.includes('collaborateurs/profile')) {
          this.sharedService.setWorkerId(null);
          this.isWorkerView = false;
        }
      }
    });
  }

  blockUnAuthorizedUrls() {
    this.router.events.subscribe((event: any) => {
      if (event) {
        let url = event?.url || event?.routerEvent.url;
        if (
          url?.includes('societes/info') &&
          !this.sharedService.getIDInfo()?.companyId
        ) {
          this.router.navigate(['/societes']);
        }
        if (
          url?.includes('societes/info/collaborateurs/profile') &&
          !this.sharedService.getIDInfo()?.workerId
        ) {
          this.router.navigate(['/societes/info/collaborateurs']);
        }
      }
    });
  }
}
