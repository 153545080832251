import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { PermissionGuard } from './core/permission.guard';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,

    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dashbord/dashbord.module').then(
            (m) => m.DashbordModule
          ),
      },

      {
        path: '',
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'societes',
        loadChildren: () =>
          import('./modules/company/company.module').then(
            (m) => m.CompanyModule
          ),
      },
      {
        path: 'societes/info/collaborateurs',
        loadChildren: () =>
          import('./modules/worker/worker.module').then((m) => m.WorkerModule),
      },
      {
        path: 'societes/info/etablissements',
        loadChildren: () =>
          import('./modules/etablissement/etablissement.module').then((m) => m.EtablissementModule),
      },
      {
        path: 'societes/info/candidats',
        loadChildren: () =>
          import('./modules/candidate/candidate.module').then((m) => m.CandidateModule),
      },
      {
        path: 'societes/info/collaborateurs/profile/planning',
        loadChildren: () =>
          import('./modules/planning/planning.module').then(
            (m) => m.PlanningModule
          ),
      },
      {
        path: 'societes/info/collaborateurs/profile/absences',
        loadChildren: () =>
          import('./modules/absence/absence.module').then(
            (m) => m.AbsenceModule
          ),
      },

      {
        path: 'societes/info/collaborateurs/profile/historique-conges',
        loadChildren: () =>
          import('./modules/leave/leave.module').then((m) => m.LeaveModule),
      },

      {
        path: 'societes/info/collaborateurs/profile/contrats',
        loadChildren: () =>
          import('./modules/contract/contract.module').then(
            (m) => m.ContractModule
          ),
      },
      {
        path: 'mon-cabinet',
        loadChildren: () =>
          import('./modules/cabinet/cabinet.module').then(
            (m) => m.CabinetModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
