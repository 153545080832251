import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { DEFAULT_LINKS, LINKS_WORKER, LINKS_COMPANY } from 'src/app/utils/links';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() isWorkerView: boolean = false;
  @Input() isCompanyView: boolean = false;

  public visible: boolean = false;
  public links: any = DEFAULT_LINKS;
  public _role!: string;
  public isCollapsed: boolean = false;
  public workerName!: string;
  constructor(
    private authService: AuthService,
    private layoutSerivce: LayoutService,
    private sharedService: SharedService,
  ) {}

  ngOnInit() {
    this.layoutSerivce.drawerMobile.subscribe((visible) => (this.visible = visible));
    this.layoutSerivce.isCollapsed.subscribe((isCollapsed) => (this.isCollapsed = isCollapsed));

    this.sharedService.__col$.subscribe((data) => {
      let workerFullName = this.sharedService.getIDInfo()?.workerFullName;
      if (workerFullName) {
        this.links = LINKS_WORKER(workerFullName).filter(
          (link: any) => !link?.authorizedRoles || link?.authorizedRoles.includes(this._role),
        );

        this.workerName = workerFullName;
      }
    });
  }

  ngOnChanges() {
    this._role = this.authService.getSession()?.role;
    if (this._role) {
      this.handleViewByRole();
      if (!this.isCompanyView && !this.isWorkerView && this._role == 'ADMINCABINET') {
        this.links = DEFAULT_LINKS;
      }

      if (this.isCompanyView && (this._role == 'ADMINSOCIETE' || this._role == 'ADMINCABINET')) {
        this.links = LINKS_COMPANY.filter(
          (link: any) => !link?.authorizedRoles || link?.authorizedRoles.includes(this._role),
        );
      }
      if (this.isWorkerView && this.workerName) {
        this.links = LINKS_WORKER(this.workerName).filter(
          (link: any) => !link?.authorizedRoles || link?.authorizedRoles.includes(this._role),
        );
      }
    }
  }

  handleViewByRole() {
    switch (this._role) {
      case 'ADMINCABINET':
        this.links = DEFAULT_LINKS;
        break;
      case 'ADMINSOCIETE':
        this.links = LINKS_COMPANY.filter(
          (link: any) => !link?.authorizedRoles || link?.authorizedRoles.includes(this._role),
        );
        break;
      default:
        this.links = LINKS_WORKER(this.workerName).filter(
          (link: any) => !link?.authorizedRoles || link?.authorizedRoles.includes(this._role),
        );
        break;
    }
  }

  openLink() {
    this.layoutSerivce.setDrawerMobile(false);
  }

  handleLogout() {
    this.authService.logout('');
  }

  toggleDropdown(index: number) {
    if (this.links && this.links[index]?.children) {
      this.links[index].isOpen = !this.links[index].isOpen;
      return;
    }

    this.openLink();
  }

  close() {
    this.visible = false;
  }
}
