import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { RuntimeEnvironment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private __col = new BehaviorSubject<any>(null);
  private __etab = new BehaviorSubject<any>(null);

  private __cpy = new BehaviorSubject<any>(null);
  private encryptionKey = RuntimeEnvironment.SECRET_KEY_ENCRYPT;

  constructor() {}

  private encrypt(data: any): string {
    const dataString = JSON.stringify(data);
    return CryptoJS.AES.encrypt(dataString, this.encryptionKey).toString();
  }

  private decrypt(data: string): any {
    const bytes = CryptoJS.AES.decrypt(data, this.encryptionKey);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  }

  setCompanyId(data: any) {
    if (!data) {
      localStorage.removeItem('__cpy');
      this.__cpy.next(null);
      return;
    }
    const encryptedData = this.encrypt(data);
    localStorage.setItem('__cpy', encryptedData);
    this.__cpy.next(data);
  }
  setWorkerId(data: any) {
    if (!data) {
      localStorage.removeItem('__col');
      this.__cpy.next(null);
      return;
    }
    const encryptedData = this.encrypt(data);
    localStorage.setItem('__col', encryptedData);
    this.__col.next(data);
  }
  setEtablissemetId(data: any) {
    if (!data) {
      localStorage.removeItem('__etab');
      this.__cpy.next(null);
      return;
    }
    const encryptedData = this.encrypt(data);
    localStorage.setItem('__etab', encryptedData);
    this.__etab.next(data);
  }
  get __cpy$() {
    const storedData = localStorage.getItem('__cpy');
    if (storedData) {
      const decryptedData = this.decrypt(storedData);
      this.__cpy.next(decryptedData);
    }
    return this.__cpy.asObservable();
  }

  get __col$() {
    const storedData = localStorage.getItem('__col');
    if (storedData) {
      const decryptedData = this.decrypt(storedData);
      this.__col.next(decryptedData);
    }
    return this.__col.asObservable();
  }

  getIDInfo(): any {
    const storedCompanyId = localStorage.getItem('__cpy');
    const storedworkerId = localStorage.getItem('__col');
    const storedEtablissementId=localStorage.getItem('__etab');

    const decryptedCpy = storedCompanyId ? this.decrypt(storedCompanyId) : null;
    const decryptedCol = storedworkerId ? this.decrypt(storedworkerId) : null;
    const  decryptedEtablissementId = storedEtablissementId ? this.decrypt(storedEtablissementId):null;
    return {
      companyId: decryptedCpy?.id,
      companyName: decryptedCpy?.companyName,
      workerId: decryptedCol?.id,
      workerFullName: decryptedCol?.workerFullName,
      etablissementId:decryptedEtablissementId?.id,
      

    };
  }
}
