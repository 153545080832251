<div class="d-flex">
  <app-sidebar
    [isCompanyView]="isCompanyView"
    [isWorkerView]="isWorkerView"
  ></app-sidebar>
  <div class="main-content">
    <app-header></app-header>
    <router-outlet></router-outlet>
  </div>
</div>
