export const environment = {
  production: true,
};
export const RuntimeEnvironment = {
  BASE_URL: 'https://maisontpe.fr:8282/api/v1',
  BASE_URL_RH: 'https://maisontpe.fr:8086',
  SOCKET_BASE_URL: 'http://maisontpe.fr',
  BASE_URL_FILES: 'http://maisontpe.fr/api/',

  DOMAIN_HOST: 'maisontpe.fr',
  SECRET_KEY_ENCRYPT: '52C8E4CD39E96671CB6859C8FFCE6',

};
